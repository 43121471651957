import React from 'react'
import { NpmInstitutionalPage } from '@plurix/ecom-pages'
import useCmsInstitutional from 'src/hooks/useCmsInstitutional'
import { LinkedinIcon } from 'src/assets/LinkedinIcon'
import { WorWithusMock } from 'src/mocks/WorkWithusMock'
import useCmsSocialMediaLinks from 'src/hooks/useCmsSocialMediaLinks'
import '../../styles/pages/trabalheConosco.scss'

const Workwithus = () => {
  const { workWithusBanners, workWithusContent } = useCmsInstitutional()
  const workWithusLinkIntern = useCmsSocialMediaLinks()

  return (
    <NpmInstitutionalPage title="Trabalhe Conosco" banners={workWithusBanners}>
      <div className="workwithus-container">
        {workWithusContent ? (
          <div dangerouslySetInnerHTML={{ __html: workWithusContent }} />
        ) : (
          <WorWithusMock />
        )}
        <p className="linkedincontainer">
          <a
            href={workWithusLinkIntern.linkLinkedin}
            target="_blank"
            rel="noreferrer"
            className="linkedin"
          >
            <LinkedinIcon /> LinkedIn
          </a>

          <a
            href={workWithusLinkIntern.linkLinkedin}
            target="_blank"
            rel="noreferrer"
            className="jobs"
          >
            <strong>Confira nossas vagas</strong>
          </a>
        </p>
      </div>
    </NpmInstitutionalPage>
  )
}

export default Workwithus
