import { graphql, useStaticQuery } from 'gatsby'
import type { InstitutionalBanners } from '@plurix/ecom-pages/src/components/sections/Institutional'

interface OurStoresContent {
  name: string
  address: string
  city: string
  neighborhood: string
  phone: string
  openingHours: string
  googleMapsLink: string
  services?: Array<Service>
}

interface Service {
  icon: string
  label: string
}

const useCmsInstitutional = () => {
  const data = useStaticQuery(
    graphql`
      query CmsInstitucionalQuery {
        cmsInstitucional {
          sections {
            data
          }
        }
      }
    `
  )

  const aboutBanners: InstitutionalBanners[] =
    data?.cmsInstitucional?.sections?.[0]?.data?.banners

  const aboutContent: string | undefined =
    data?.cmsInstitucional?.sections?.[0]?.data?.content

  const storeBanners: InstitutionalBanners[] =
    data?.cmsInstitucional?.sections?.[1]?.data?.banners

  const storeContent: OurStoresContent[] =
    data?.cmsInstitucional?.sections?.[1]?.data?.stores

  const privacyBanners: InstitutionalBanners[] =
    data?.cmsInstitucional?.sections?.[2]?.data?.banners

  const termsBanners: InstitutionalBanners[] =
    data?.cmsInstitucional?.sections?.[3]?.data?.banners

  const attendanceBanners: InstitutionalBanners[] =
    data?.cmsInstitucional?.sections?.[4]?.data?.banners

  const attendanceContent: string | undefined =
    data?.cmsInstitucional?.sections?.[4]?.data?.content

  const workWithusBanners: InstitutionalBanners[] =
    data?.cmsInstitucional?.sections?.[5]?.data?.banners

  const workWithusContent: string | undefined =
    data?.cmsInstitucional?.sections?.[5]?.data?.content

  return {
    aboutBanners,
    aboutContent,
    storeBanners,
    storeContent,
    privacyBanners,
    termsBanners,
    attendanceBanners,
    attendanceContent,
    workWithusBanners,
    workWithusContent,
  }
}

export default useCmsInstitutional
